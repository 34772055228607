import { forwardRef } from 'react';

export type ContainerProps = {
  className?: string;
  children: React.ReactNode;
  responsive?: boolean;
};

const Container = forwardRef<HTMLDivElement, ContainerProps>(
  ({ className, children, responsive = true }: ContainerProps, ref) => {
    return (
      <div
        style={{ scrollbarWidth: 'none' }}
        ref={ref}
        className={`${
          responsive ? 'container px-4 xl:px-0' : ''
        } mx-auto px-4 xl:px-0 py-4 min-h-[calc(100dvh-64px)] ${className}`}
      >
        {children}
      </div>
    );
  }
);

export default Container;
