export function getBrowserInfo() {
  const userAgent = navigator.userAgent;
  let browserName = 'Unknown';
  let fullVersion = 'Unknown';

  if (userAgent.indexOf('Firefox') > -1) {
    browserName = 'Mozilla Firefox';
    fullVersion = userAgent.substring(userAgent.indexOf('Firefox') + 8);
  } else if (userAgent.indexOf('SamsungBrowser') > -1) {
    browserName = 'Samsung Internet';
    fullVersion = userAgent.substring(userAgent.indexOf('SamsungBrowser') + 15);
  } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
    browserName = 'Opera';
    fullVersion = userAgent.substring(userAgent.indexOf('Opera') + 6);
    if (userAgent.indexOf('OPR') > -1) {
      fullVersion = userAgent.substring(userAgent.indexOf('OPR') + 4);
    }
  } else if (userAgent.indexOf('Trident') > -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = userAgent.substring(userAgent.indexOf('rv:') + 3);
  } else if (userAgent.indexOf('Edge') > -1) {
    browserName = 'Microsoft Edge';
    fullVersion = userAgent.substring(userAgent.indexOf('Edge') + 5);
  } else if (userAgent.indexOf('Chrome') > -1) {
    browserName = 'Google Chrome';
    fullVersion = userAgent.substring(userAgent.indexOf('Chrome') + 7);
  } else if (userAgent.indexOf('Safari') > -1) {
    browserName = 'Apple Safari';
    fullVersion = userAgent.substring(userAgent.indexOf('Safari') + 7);
    if (userAgent.indexOf('Version') > -1) {
      fullVersion = userAgent.substring(userAgent.indexOf('Version') + 8);
    }
  }

  // Extract the version number
  if (fullVersion.indexOf(';') > -1) {
    fullVersion = fullVersion.split(';')[0];
  }
  if (fullVersion.indexOf(' ') > -1) {
    fullVersion = fullVersion.split(' ')[0];
  }

  return {
    name: browserName,
    version: fullVersion,
  };
}
