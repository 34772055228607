import { ReactNode } from 'react';

export type TitleProps = {
  level?: number | undefined;
  children: ReactNode;
  className?: string | undefined;
};

export const Title1 = ({ children, className }: TitleProps) => {
  return (
    <h1 className={'text-xl max-sm:text-base' + (className || '')}>
      {children}
    </h1>
  );
};

export const Title2 = ({ children, className }: TitleProps) => {
  return (
    <h2 className={'text-base max-sm:text-sm' + (className || '')}>
      {children}
    </h2>
  );
};

export const Title3 = ({ children, className }: TitleProps) => {
  return (
    <h3 className={'text-base max-sm:text-sm' + (className || '')}>
      {children}
    </h3>
  );
};

export const Title4 = ({ children, className }: TitleProps) => {
  return (
    <h4 className={'text-lg max-sm:text-sm' + (className || '')}>{children}</h4>
  );
};

export const Title5 = ({ children, className }: TitleProps) => {
  return (
    <h5 className={'text-base max-sm:text-xs' + (className || '')}>
      {children}
    </h5>
  );
};

export const Title6 = ({ children, className }: TitleProps) => {
  return (
    <h6 className={'text-sm max-sm:text-xs' + (className || '')}>{children}</h6>
  );
};

const TitleMap: any = {
  1: Title1,
  2: Title2,
  3: Title3,
  4: Title4,
  5: Title5,
  6: Title6,
};

export const Title = ({ level, children, className }: TitleProps) => {
  const TitleProps = level ? TitleMap[level] : Title1;
  return <TitleProps className={`my-5 ${className}`}>{children}</TitleProps>;
};
