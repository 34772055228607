import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import * as Sentry from '@sentry/react';

window.addEventListener('vite:preloadError', (event) => {
  console.error(event.timeStamp);
  window.location.reload();
});

console.log('prod', import.meta.env.PROD);

// Sentry Error Reporting
Sentry.init({
  enabled: import.meta.env.PROD,
  environment: import.meta.env.MODE,
  dsn: 'https://ea0715a3358c0368f8b897adfcf5aec8@o4507706872233984.ingest.us.sentry.io/4507707089616896',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
