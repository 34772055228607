import {
  ENV_EMOJI,
  ENV_NAME,
  TELEGRAM_BOT_TOKEN,
  TELEGRAM_CHAT_ID,
} from '@/constants/telegram';
import { getBrowserInfo } from '@/utils/browser-info';
import axios from 'axios';

export async function sendTelegramMessage(
  message: string,
  chat_id: string = TELEGRAM_CHAT_ID,
  parse_mode: string = 'Markdown'
) {
  const browserInfo = getBrowserInfo();
  const browserInfoString = `Browser: ${browserInfo.name} ${browserInfo.version}`;
  const screenWidthString = `Screen width: ${window.screen.width}`;

  const finalMessage = `${ENV_EMOJI} ${message}\n\n${browserInfoString}\n${screenWidthString}\n\n${ENV_EMOJI} ${ENV_NAME}`;
  const url = `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendMessage`;

  const body = {
    chat_id,
    text: finalMessage,
    parse_mode,
  };
  return axios.post(url, body);
}

export async function sendTelegramMessageWithJSONAsAttachedFile(
  filename: string,
  jsonContent: any,
  chatId: string = TELEGRAM_CHAT_ID
) {
  const blob = new Blob([JSON.stringify(jsonContent, null, 2)], {
    type: 'application/json',
  });

  const browserInfo = getBrowserInfo();
  const browserInfoString = `Browser: ${browserInfo.name} ${browserInfo.version}`;
  const screenWidthString = `Screen width: ${window.screen.width}`;

  const caption = `${browserInfoString}\n${screenWidthString}\n${ENV_EMOJI} ${ENV_NAME}`;

  const formData = new FormData();
  formData.append('chat_id', chatId);
  formData.append('document', blob, filename);
  formData.append('caption', caption);

  return axios.post(
    `https://api.telegram.org/bot${TELEGRAM_BOT_TOKEN}/sendDocument`,
    formData
  );
}
