import { lazy } from 'react';

export const LazyCadasterTransferPage = lazy(
  () => import('./CadasterTransfer/CadasterTransferPage')
);

export const LazyCadasterTransferUpdatePage = lazy(
  () => import('./CadasterTransfer/TransferUpdatePage')
);

export const LazyCadasterTransferDetail = lazy(
  () => import('./CadasterTransfer/TransferDetail')
);

export const LazySubDivisionPage = lazy(
  () => import('./SubDivision/SubDivisionPage')
);

export const LazySubDivisionDetail = lazy(
  () => import('./SubDivision/SubdivisionDetail')
);

// export const LazySubDivisionConfirmPage = lazy(
//   () => import('./SubDivision/SubDivisionConfirm')
// );

export const LazySubDivisionUpdatePage = lazy(
  () => import('./SubDivision/SubdivisionUpdate')
);

export const LazyAmalgamationPage = lazy(
  () => import('./Almagation/AmalgamationPage')
);

export const LazyAmalgamationDetail = lazy(
  () => import('./Almagation/AmalgamationDetail')
);

// export const LazyAmalgamationConfirm = lazy(
//   () => import('./Almagation/AmalgamationConfirm')
// );

export const LazyAmalgamationUpdatePage = lazy(
  () => import('./Almagation/AmalgamationUpdate')
);

export const LazyBurdenDeregistrationPage = lazy(
  () => import('./BurdenDeregistration/BurdenDeregistrationPage')
);

export const LazyBurdenDeregistrationDetail = lazy(
  () => import('./BurdenDeregistration/BurdenDeregistrationDetail')
);

export const LazyBurdenRegistrationPage = lazy(
  () => import('./BurdenRegistration/BurdenRegistrationPage')
);

export const LazyBurdenDetailPage = lazy(
  () => import('./BurdenRegistration/BurdenDetail')
);

export const LazySubSequentPage = lazy(() => import('./SubSequentPage'));
