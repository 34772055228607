import PasswordForm from '@/components/Authentication/PasswordForm';
import { Title } from '@/components/Title';
import useTitle from '@/hooks/useTitle';
import Container from '@/layouts/Container';
import { Card, Space } from 'antd';

const PasswordPage = () => {
  useTitle('ប្ដូរលេខសម្ងាត់ | CIS');

  return (
    <Container className="flex flex-col justify-center items-center">
      <div className="w-full max-w-md">
        <Card>
          <Space direction="vertical" className="w-full">
            <Title level={1}>ប្ដូរលេខសម្ងាត់</Title>
            <PasswordForm />
          </Space>
        </Card>
      </div>
    </Container>
  );
};

export default PasswordPage;
