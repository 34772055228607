import { RouteObject, createBrowserRouter, Navigate } from 'react-router-dom';
import { lazy, Suspense } from 'react';
import { Permission } from '@/constants/roleAndPermission';
// import { Spin } from 'antd';
import {
  LazyAmalgamationDetail,
  LazyAmalgamationPage,
  LazyAmalgamationUpdatePage,
  LazyBurdenDeregistrationDetail,
  LazyBurdenDeregistrationPage,
  LazyBurdenDetailPage,
  LazyBurdenRegistrationPage,
  LazyCadasterTransferDetail,
  LazyCadasterTransferPage,
  LazyCadasterTransferUpdatePage,
  LazySubDivisionDetail,
  LazySubDivisionPage,
  LazySubDivisionUpdatePage,
  LazySubSequentPage,
} from './SubSequentRegistration';
import {
  LazyAdminLayout,
  LazyAuthorizedLayout,
  LazyNotFoundPage,
} from '@/layouts';
import { LazyLoginPage } from './Login';
import { LazyCadasterPage } from './Land';
import { LazyParcelDetail } from './ParcelDetail';
import { LazyCadasterForm } from './SystematicRegistration';
import { RegistrationType } from '@/constants/codelist';
import { LazyCadasterTaxPage } from './CadasterTax';
import { Skeleton } from 'antd';
import PasswordPage from './Password/PasswordPage';
import {
  LazySequestrationDeregister,
  LazySequestrationDetail,
  LazySequestrationPage,
} from './SubSequentRegistration/Sequestration';

const PrintLandDetail = lazy(() => import('./Print/LandDetail'));

const LazySettingPage = lazy(() => import('./Setting/SettingPage'));
const LazyLandDocumentPage = lazy(
  () => import('./LandDocument/LandDocumentPage')
);
const LazyDocCheckListPage = lazy(
  () => import('./DocCheckList/DocCheckListPage')
);
const LandDetailQrPage = lazy(() => import('./LandDetailQr/LandDetailQrPage'));
const PrintPage = lazy(() => import('./Print/PrintPage'));
const LazyOwnershipCertificate = lazy(
  () => import('./Print/OwnershipCertificate')
);
const LazyNameChecklist = lazy(() => import('./Print/NameChecklist'));
const LazyIssuedTile = lazy(() => import('./Print/IssuedTitle'));
const LazyPublicDisplay = lazy(() => import('./Print/PublishDisplay'));
const LazyLandRegistry = lazy(() => import('./Print/LandRegistryBook'));
const LazySignVerifyKh = lazy(() => import('./Print/SignVerifyKh'));
const LazyInvoice = lazy(() => import('./Print/Invoice'));
const LazyLandRegistryCover = lazy(
  () => import('./Print/Cover/LandRegistryCover')
);
const LazyPDVisitCover = lazy(() => import('./Print/Cover/PDVisitCover'));
const LazyNameCheckListCover = lazy(
  () => import('./Print/Cover/NameCheckListCover')
);
const LazyIssuedTitle = lazy(() => import('./Print/Cover/IssuedTitleCover'));
const LazyLandOwner = lazy(() => import('./Print/Cover/LandOwnerCover'));
const LazyOwnershipQuote = lazy(() => import('./Print/Cover/OwnershipQuote'));
const EmptyLazyLandRegistryCover = lazy(
  () => import('./Print/EmptyLandRegistryBook')
);
const LazyPublicDisplayVisited = lazy(
  () => import('./Print/PublishDisplayVisited')
);

const createLazyComponent = (
  Component: React.ComponentType<any>,
  permissions?: Permission[],
  props?: any
) => {
  if (permissions && permissions.length > 0) {
    return (
      <Suspense fallback={<Skeleton active className="p-4" />}>
        <LazyAuthorizedLayout permissions={permissions}>
          <Component {...props} />
        </LazyAuthorizedLayout>
      </Suspense>
    );
  } else {
    return (
      <Suspense fallback={<Skeleton active className="p-4" />}>
        <Component {...props} />
      </Suspense>
    );
  }
};

export const routes: RouteObject[] = [
  {
    path: '/',
    element: <Navigate to="/admin" replace />,
  },
  {
    path: '/admin',
    element: createLazyComponent(LazyAdminLayout),
    children: [
      {
        index: true,
        element: <Navigate to="cadaster" replace />,
      },
      {
        path: 'change-password',
        element: <PasswordPage />,
      },
      {
        path: 'cadaster',
        children: [
          {
            index: true,
            element: createLazyComponent(LazyCadasterPage, [
              Permission.SYSTEMATIC_REGISTRATION_LIST,
            ]),
          },
          {
            path: ':parcelId',
            element: createLazyComponent(LazyParcelDetail, [
              Permission.READ_ONLY,
              Permission.SYSTEMATIC_REGISTRATION_LIST,
            ]),
          },
          {
            path: 'docs_checklist',
            children: [
              {
                path: ':parcelId',
                element: createLazyComponent(LazyDocCheckListPage, [
                  Permission.SYSTEMATIC_REGISTRATION_UPDATE,
                ]),
              },
            ],
          },
          {
            path: 'form',
            children: [
              {
                index: true,
                element: createLazyComponent(LazyCadasterForm, [
                  Permission.SYSTEMATIC_REGISTRATION_CREATE,
                ]),
              },
              {
                path: ':parcelId',
                element: createLazyComponent(LazyCadasterForm, [
                  Permission.SYSTEMATIC_REGISTRATION_UPDATE,
                ]),
              },
            ],
          },
          {
            path: 'additional-reg',
            children: [
              {
                path: ':parcelId',
                element: createLazyComponent(
                  LazyCadasterForm,
                  [Permission.SYSTEMATIC_REGISTRATION_UPDATE],
                  { type: RegistrationType.Additional }
                ),
              },
            ],
          },
          {
            path: 'document-form',
            children: [
              {
                index: true,
                element: createLazyComponent(LazyLandDocumentPage),
              },
              {
                path: ':landId',
                element: createLazyComponent(LazyLandDocumentPage),
              },
            ],
          },
          {
            path: 'tax',
            children: [
              {
                path: ':parcelId',
                element: createLazyComponent(LazyCadasterTaxPage, [
                  Permission.SYSTEMATIC_REGISTRATION_UPDATE,
                ]),
              },
            ],
          },
        ],
      },
      {
        path: 'print',
        children: [
          {
            index: true,
            element: createLazyComponent(PrintPage),
          },
          {
            path: 'land-detail',
            element: createLazyComponent(PrintLandDetail, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'ownership-certificate',
            element: createLazyComponent(LazyOwnershipCertificate, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'name-checklist',
            element: createLazyComponent(LazyNameChecklist, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'issued-title',
            element: createLazyComponent(LazyIssuedTile, [Permission.PRINTING]),
          },
          {
            path: 'public-display',
            element: createLazyComponent(LazyPublicDisplay, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'land-registry',
            element: createLazyComponent(LazyLandRegistry, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'sign-verify-kh',
            element: createLazyComponent(LazySignVerifyKh, [
              Permission.SIGN_QR,
            ]),
          },
          {
            path: 'invoice',
            element: createLazyComponent(LazyInvoice, [Permission.PRINTING]),
          },
          {
            path: 'empty-land-registry',
            element: createLazyComponent(EmptyLazyLandRegistryCover, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'pd-visit',
            element: createLazyComponent(LazyPublicDisplayVisited, [
              Permission.PRINTING,
            ]),
          },
          {
            path: 'cover',
            children: [
              {
                path: 'land-registry',
                element: createLazyComponent(LazyLandRegistryCover, [
                  Permission.PRINTING,
                ]),
              },
              {
                path: 'pd-visit',
                element: createLazyComponent(LazyPDVisitCover, [
                  Permission.PRINTING,
                ]),
              },
              {
                path: 'name-check-list',
                element: createLazyComponent(LazyNameCheckListCover, [
                  Permission.PRINTING,
                ]),
              },
              {
                path: 'issued-title',
                element: createLazyComponent(LazyIssuedTitle, [
                  Permission.PRINTING,
                ]),
              },
              {
                path: 'land-owner',
                element: createLazyComponent(LazyLandOwner, [
                  Permission.PRINTING,
                ]),
              },
              {
                path: 'ownership-quote',
                element: createLazyComponent(LazyOwnershipQuote, [
                  Permission.PRINTING,
                ]),
              },
            ],
          },
        ],
      },
      {
        path: 'setting',
        element: createLazyComponent(LazySettingPage),
      },
      {
        path: 'qr-detail',
        element: createLazyComponent(LandDetailQrPage),
      },
      {
        path: 'sub-reg',
        children: [
          {
            index: true,
            element: createLazyComponent(LazySubSequentPage, [
              Permission.SUB_SEQUENT_LIST,
            ]),
          },
          {
            path: 'transfer',
            children: [
              {
                index: true,
                element: createLazyComponent(LazyCadasterTransferPage, [
                  Permission.SUB_SEQUENT_CREATE,
                ]),
              },
              {
                path: 'edit/:appId',
                element: createLazyComponent(LazyCadasterTransferPage, [
                  Permission.SUB_SEQUENT_UPDATE,
                ]),
              },
              {
                path: ':appId',
                element: createLazyComponent(LazyCadasterTransferDetail, [
                  Permission.SUB_SEQUENT_LIST,
                ]),
              },
              {
                path: 'confirm/:appId',
                element: createLazyComponent(
                  LazyCadasterTransferUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'confirm' }
                ),
              },
              {
                path: 'update/:appId',
                element: createLazyComponent(
                  LazyCadasterTransferUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'update' }
                ),
              },
            ],
          },

          {
            path: 'burden',
            children: [
              {
                index: true,
                element: createLazyComponent(LazyBurdenRegistrationPage, [
                  Permission.SUB_SEQUENT_CREATE,
                ]),
              },
              {
                path: ':appId',
                element: createLazyComponent(LazyBurdenDetailPage, [
                  Permission.SUB_SEQUENT_LIST,
                ]),
              },
            ],
          },
          {
            path: 'deregistration',
            children: [
              {
                index: true,
                element: createLazyComponent(LazyBurdenDeregistrationPage, [
                  Permission.SUB_SEQUENT_CREATE,
                ]),
              },
              {
                path: ':appId',
                element: createLazyComponent(LazyBurdenDeregistrationDetail, [
                  Permission.SUB_SEQUENT_LIST,
                ]),
              },
            ],
          },

          {
            path: 'sequestration',
            children: [
              {
                path: 'registration',
                children: [
                  {
                    index: true,
                    element: createLazyComponent(LazySequestrationPage, [
                      Permission.SUB_SEQUENT_CREATE,
                    ]),
                  },
                  {
                    path: ':appId',
                    element: createLazyComponent(
                      LazySequestrationDetail,
                      [Permission.SUB_SEQUENT_LIST],
                      { type: 'registration' }
                    ),
                  },
                ],
              },
              {
                path: 'deregistration',
                children: [
                  {
                    index: true,
                    element: createLazyComponent(LazySequestrationDeregister, [
                      Permission.SUB_SEQUENT_CREATE,
                    ]),
                  },
                  {
                    path: ':appId',
                    element: createLazyComponent(
                      LazySequestrationDetail,
                      [Permission.SUB_SEQUENT_LIST],
                      { type: 'deregistration' }
                    ),
                  },
                ],
              },
            ],
          },

          {
            path: 'subdivision',
            children: [
              // {
              //   index: true,
              //   element: createLazyComponent(LazySubDivisionPage),
              // },
              {
                path: ':appId',
                element: createLazyComponent(LazySubDivisionDetail, [
                  Permission.SUB_SEQUENT_LIST,
                ]),
              },
              {
                path: 'edit/:appId',
                element: createLazyComponent(LazySubDivisionPage, [
                  Permission.SUB_SEQUENT_UPDATE,
                ]),
              },
              {
                path: 'confirm/:appId',
                element: createLazyComponent(
                  LazySubDivisionUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'confirm' }
                ),
              },
              {
                path: 'update/:appId',
                element: createLazyComponent(
                  LazySubDivisionUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'update' }
                ),
              },
            ],
          },
          {
            path: 'amalgamation',
            children: [
              // {
              //   index: true,
              //   element: createLazyComponent(LazyAmalgamationPage),
              // },
              {
                path: ':appId',
                element: createLazyComponent(LazyAmalgamationDetail, [
                  Permission.SUB_SEQUENT_CREATE,
                ]),
              },
              {
                path: 'edit/:appId',
                element: createLazyComponent(LazyAmalgamationPage, [
                  Permission.SUB_SEQUENT_UPDATE,
                ]),
              },
              {
                path: 'confirm/:appId',
                element: createLazyComponent(
                  LazyAmalgamationUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'confirm' }
                ),
              },
              {
                path: 'update/:appId',
                element: createLazyComponent(
                  LazyAmalgamationUpdatePage,
                  [
                    Permission.SUB_SEQUENT_CREATE,
                    Permission.SUB_SEQUENT_UPDATE,
                  ],
                  { type: 'update' }
                ),
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/login',
    element: createLazyComponent(LazyLoginPage),
  },

  {
    path: '*',
    element: createLazyComponent(LazyNotFoundPage),
  },
];

export const router = createBrowserRouter(routes);
