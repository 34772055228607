import { API_URL } from '@/configs/environment';
import { http } from '@/libs/http';

export const login = async (data: any = {}) => {
  const response = await http.post(`${API_URL}/login`, data);
  return response?.data;
};

export const logout = async () => {
  const response = await http.post(`${API_URL}/logout`);
  return response?.data;
};

export const changePassword = async (data: any = {}) => {
  const response = await http.post(`${API_URL}/change-password`, data);
  return response?.data;
};
