/// <reference types="vite-plugin-svgr/client" />
import Compass from '@/assets/compass.svg?react';
import '@/styles/animated-loading.css';

interface AnimatedCompassProps extends React.SVGProps<SVGSVGElement> {
  size?: number;
  className?: string;
}

const AnimatedCompass: React.FC<AnimatedCompassProps> = ({
  size = 50,
  className,
  ...svgProps
}) => {
  return (
    <Compass
      className={className}
      style={{ width: size, height: size }}
      {...svgProps}
    />
  );
};

export default AnimatedCompass;
