import { API_URL } from '@/configs/environment';
import { ERROR_LOGS_CHAT_ID } from '@/constants/telegram';
import {
  sendTelegramMessage,
  sendTelegramMessageWithJSONAsAttachedFile,
} from '@/integrations/telegram-api';
import useAuthStore from '@/stores/auth.store';
import { getBrowserInfo } from '@/utils/browser-info';
import axios, { AxiosError } from 'axios';

const instance = axios.create({
  headers: { 'Content-Type': 'application/json; charset=utf-8' },
  baseURL: API_URL,
  method: 'GET',
});

instance.interceptors.request.use(
  function (config) {
    const token = useAuthStore.getState().accessToken;
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    console.log('request error', error);
    const browserInfo = getBrowserInfo();
    const statusCode = error.response?.status || '';

    let humanizedErrorInfo = `Code: ${error.code}`;
    if (error.response?.status) {
      humanizedErrorInfo += `\nStatus: ${error.response.status}`;
    }
    const urlMessage = `url: ${error.config?.url}`;
    humanizedErrorInfo += `\n${urlMessage}`;
    humanizedErrorInfo += `\nCause: ${error.cause}`;
    humanizedErrorInfo += `\nBrowser: ${browserInfo.name} ${browserInfo.version}`;

    const errorMessageFormatted = `\`\`\`${error.message}\`\`\``;
    const telegramMessage = `**❌ Global Axios Request Error\n\n${humanizedErrorInfo}\n\nError:${errorMessageFormatted}\n\n${location.href}`;
    sendTelegramMessage(telegramMessage, ERROR_LOGS_CHAT_ID);

    const errorJson = error.toJSON();
    sendTelegramMessageWithJSONAsAttachedFile(
      `${statusCode}_axios_response_error.json`,
      errorJson,
      ERROR_LOGS_CHAT_ID
    );
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  function (response) {
    if (response.data.error && +response.data.error !== 0) {
      return Promise.reject(response.data);
    }
    return response;
  },
  async function (error: AxiosError) {
    const browserInfo = getBrowserInfo();
    const statusCode = error.response?.status || '';

    let humanizedErrorInfo = `Code: ${error.code}`;
    if (error.response?.status) {
      humanizedErrorInfo += `\nStatus: ${error.response.status}`;
    }
    humanizedErrorInfo += `\nCause: ${error.cause}`;
    const urlMessage = `url: ${error.config?.url}`;
    humanizedErrorInfo += `\n${urlMessage}`;
    humanizedErrorInfo += `\nBrowser: ${browserInfo.name} ${browserInfo.version}`;

    const errorMessageFormatted = `\`\`\`${error.message}\`\`\``;
    const telegramMessage = `**❌ Global Axios Response Error\n\n${humanizedErrorInfo}\n\nError:${errorMessageFormatted}\n\n${location.href}`;
    sendTelegramMessage(telegramMessage, ERROR_LOGS_CHAT_ID);

    const errorJson = error.toJSON();
    sendTelegramMessageWithJSONAsAttachedFile(
      `${statusCode}_axios_response_error.json`,
      errorJson,
      ERROR_LOGS_CHAT_ID
    );

    if (error.response?.status === 401) {
      useAuthStore.setState({ isAuthenticated: false, accessToken: null });
    }
    return Promise.reject(error);
  }
);

export { instance as http };
