const {
  VITE_BASE_URL,
  VITE_FILE_URL,
  VITE_FILE_BUCKET,
  VITE_FINGERPRINT_URL,
  VITE_PDF_GENERATOR_URL,
  BASE_URL,
  VITE_PRINT_URL,
  VITE_AVATAR_BUCKET,
} = import.meta.env;
import { version } from '../../package.json';

export const LOCAL_IP = BASE_URL;

export const API_URL = `${VITE_BASE_URL}/api`;
export const PRINT_URL = VITE_PRINT_URL;

const FILE_BUCKET = VITE_FILE_BUCKET;
const AVATAR_BUCKET = VITE_AVATAR_BUCKET;
export const FILE_URL = VITE_FILE_URL;

export const FILE_DOWNLOAD_URL = `${FILE_URL}/get/${FILE_BUCKET}`;
export const FILE_UPLOAD_URL = `${FILE_URL}/upload/${FILE_BUCKET}`;
export const FILE_AVATAR_URL = `${FILE_URL}/get/${AVATAR_BUCKET}`;

export const FINGERPRINT_DOWNLOAD_URL = `${VITE_FINGERPRINT_URL}/get`;

export const PDF_GENERATOR_SERVER_URL = VITE_PDF_GENERATOR_URL;

export const APP_VERSION = version;

export const isTraining = VITE_BASE_URL.includes('-cis-training.');
export const isTrainingDev = VITE_BASE_URL.includes(
  'api.training.cis.khmember.com'
);
export const isLocalHost = VITE_BASE_URL.includes('localhost');
export const isNextRelease = VITE_BASE_URL.includes('-cis-next.');
export const isProduction = VITE_BASE_URL.includes('api-cis.mlmupc.gov.kh');
