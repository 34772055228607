import { lazy } from 'react';

export const LazySequestrationPage = lazy(() => import('./SequestrationPage'));

export const LazySequestrationDeregister = lazy(
  () => import('./SequestrationDeregister')
);

export const LazySequestrationConfirm = lazy(
  () => import('./SequestrationConfirm')
);

export const LazySequestrationDetail = lazy(
  () => import('./SequestrationDetail')
);
