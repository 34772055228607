export enum Nationality {
  Khmer = 1,
}

export enum IdCardType {
  NationalId = 1,
  Temporary = 2,
}

export enum PersonSex {
  Male = 0,
  Female = 1,
}

export enum CivilStatus {
  Husband = 1,
  Wife = 2,
  Single = 3,
  Widower = 4,
  Divorced = 5,
  Absent = 6,
  Deceased = 7,
  Incapable = 8,
  Other = 99,
}

export enum PropertyType {
  /** ទ្រព្យខាងប្ដី */
  property_husband = 1,
  /** ទ្រព្យខាងប្រពន្ធ */
  property_wife = 2,
  /** ទ្រព្យលីវ */
  single_property = 3,
  /** ទ្រព្យខាងរួមប្ដីប្រពន្ធ */
  common_husband_wife = 4,
  /** ទ្រព្យរដ្ឋ */
  state_property = 5,
  /** និតិបុគ្គលសាធារណៈផ្សេងពីរដ្ឋ */
  public_legal = 6,
  /** ទ្រព្យវត្ត */
  pagoda = 7,
  /** សហគមន៍ជនជាតិដើមភាគតិច */
  indigenous = 8,
  /** ក្រុមហ៊ុន */
  company = 9,
  /** ទ្រព្យសមាគម */
  association = 10,
  /** ព្រះរាជទ្រព្យ */
  crown = 11,
  /** កម្មសិទ្ធិអវិភាគ */
  undivided_ownership = 12,
  /** សហកម្មសិទ្ធិ */
  coownership = 13,
}

export enum LandRightPersonRole {
  /** ម្ចាស់កម្មសិទ្ធ */
  owner = 1,
  /** អ្នកតំណាង */
  representative = 2,
}

export enum PersonCategory {
  /** នីតិបុគ្កលសាធារណៈ */
  PublicLegal = 1,
  /** នីតិបុគ្កលឯកជន */
  PrivateLegal = 2,
  /** សហគមន័ */
  Community = 3,
  /** រូបវន្តបុក្គល */
  Person = 4,
}

export enum UserPosition {
  /** មន្រ្តីវិនិច្ឆ័យ */
  Adjudicator = 1,
  /** ជំនួយការមន្រ្តីវិនិច្ឆ័យ */
  AdjudicatorAssistant = 2,
  /** អ្នកគ្រប់គ្រងតំបន់វិនិច្ឆ័យ */
  FieldManager = 3,
  /** មន្ត្រីវាស់វែង */
  Surveyor = 4,
  /** ជំនួយការមន្ត្រីវាស់វែង */
  MeasurementOfficerAssistant = 5,
  /** មន្ត្រីបញ្ជូលទិន្នន័យ */
  DataEntry = 6,
  /** មន្ត្រីកំណត់ព្រំ */
  BoundaryOfficer = 7,
  /** ប្រធានមន្ទីរ ដនសស រាជធានីខេត្ត */
  PDD = 8,
  /** អនុប្រធានមន្ទីរ ដនសស រាជធានីខេត្ត */
  PDDD = 9,
  /** ប្រធានការិយាល័យអភិរក្សសុរិយោដីរាជធានីខេត្ត */
  PCCOD = 10,
  /** អនុប្រធានការិយាល័យអភិរក្សសុរិយោដីរាជធានីខេត្ត */
  PCCODD = 11,
  /** ប្រធានភូមិបាល */
  CLO = 12,
  /** អនុប្រធានភូមិបាល */
  DCLO = 13,
  /** មន្រ្តីភូ្មមិបាល */
  LandOfficer = 14,
  /** ចៅសង្កាត់/មេឃុំ */
  CommuneChief = 15, //ចៅសង្កាត់/មេឃុំ
}

export enum SubdivisionType {
  /** បំបែកផ្ទាល់ខ្លួន */
  SelfSubdivison = 1,
  /** បំបែកផ្ទេរសិទ្ធ */
  TransferSubdivision = 2,
}

export const verifyQrStatus = {
  '1': {
    label: 'រង់ចាំការ Sign',
    color: 'orange',
  },
  '2': {
    label: 'បានលុប',
    color: 'red',
  },
  '3': {
    label: 'បានបច្ចុប្បន្នភាព',
    color: 'blue',
  },
  '4': {
    label: 'បាន Sign',
    color: 'green',
  },
  '5': {
    label: 'ផុតសុពលភាព',
    color: 'red',
  },
};

export const SubdivisionTypes = [
  {
    code: SubdivisionType.SelfSubdivison,
    description_khmer: 'បំបែកផ្ទាល់ខ្លួន',
  },
  {
    code: SubdivisionType.TransferSubdivision,
    description_khmer: 'បំបែកផ្ទេរសិទ្ធ',
  },
];

export enum AppStatus {
  INPUT_PENDING = 1,
  APPROVE_PENDING = 2,
  APPROVED = 3,
  CANCELED = 4,
}

type AppStatusAttribute = {
  [key in AppStatus]: { value: number; label: string; color: string };
};

export const AppStatusAttributes: AppStatusAttribute = {
  [AppStatus.INPUT_PENDING]: {
    value: 1,
    label: 'រង់ចាំបញ្ចូលទិន្នន័យ',
    color: 'orange',
  },
  [AppStatus.APPROVE_PENDING]: {
    value: 2,
    label: 'រង់ចាំឯកភាព',
    color: 'geekblue',
  },
  [AppStatus.APPROVED]: {
    value: 3,
    label: 'បានឯកភាព',
    color: 'green',
  },
  [AppStatus.CANCELED]: {
    value: 4,
    label: 'បោះបង់',
    color: 'red',
  },
};

export const appStatusList = Object.keys(AppStatusAttributes).map(
  (key) =>
    AppStatusAttributes[
      key as unknown as AppStatus
    ] as AppStatusAttribute[keyof AppStatusAttribute]
);

export enum RegistrationType {
  Systematic = 1,
  Additional = 2,
  Sporadic = 3,
  Subsquent = 4,
  Merge = 5,
  Subdivision = 6,
  Lease = 7,
  RegForUse = 8,
  State = 9,
  Community = 10,
}

export interface PropertyTypeCondition {
  minOwners: number;
  maxOwners: number;
  minRepresentatives?: number;
  maxRepresentatives?: number;
  ownerSex?: PersonSex;
  checkMinor?: boolean;
  checkIncapable?: boolean;
  checkOwnerCount?: boolean;
  checkHusbandAndWife?: boolean;
  personCategory?: PersonCategory[];
  ownerShare?: number;
}

const defaultPropertyTypeCondition: PropertyTypeCondition = {
  minOwners: 1,
  maxOwners: 1,
  // ... default values for other properties if needed
};

export const PropertyTypeConditions: Record<
  PropertyType,
  PropertyTypeCondition
> = {
  [PropertyType.property_husband]: {
    minOwners: 2,
    maxOwners: 2,
    ownerSex: PersonSex.Male,
    personCategory: [PersonCategory.Person],
    ownerShare: 100,
  },
  [PropertyType.property_wife]: {
    minOwners: 2,
    maxOwners: 2,
    ownerSex: PersonSex.Female,
    personCategory: [PersonCategory.Person],
    ownerShare: 100,
  },
  [PropertyType.single_property]: {
    minOwners: 1,
    maxOwners: 1,
    checkMinor: true,
    checkIncapable: true,
    personCategory: [PersonCategory.Person],
    ownerShare: 100,
  },
  [PropertyType.common_husband_wife]: {
    minOwners: 2,
    maxOwners: 2,
    checkHusbandAndWife: true,
    personCategory: [PersonCategory.Person],
    ownerShare: 50,
  },
  [PropertyType.undivided_ownership]: {
    minOwners: 2,
    maxOwners: 999, // No upper limit
    checkMinor: true,
    checkOwnerCount: true,
    checkIncapable: true,
    personCategory: [PersonCategory.Person],
  },
  [PropertyType.coownership]: {
    minOwners: 2,
    maxOwners: 999, // No upper limit
    checkMinor: true,
    checkIncapable: true,
    personCategory: [PersonCategory.Person],
    ownerShare: 50,
  },
  [PropertyType.state_property]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.PublicLegal],
    ownerShare: 100,
  },
  [PropertyType.public_legal]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.PublicLegal],
    ownerShare: 100,
  },
  [PropertyType.pagoda]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.PublicLegal],
    ownerShare: 100,
  },
  [PropertyType.indigenous]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.Community],
    ownerShare: 100,
  },
  [PropertyType.company]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.PrivateLegal],
    ownerShare: 100,
  },
  [PropertyType.association]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.PrivateLegal],
    ownerShare: 100,
  },
  [PropertyType.crown]: {
    ...defaultPropertyTypeCondition,
    personCategory: [PersonCategory.Person],
  },
};

export const getPropertyTypeCondition = (
  propertyType: PropertyType
): PropertyTypeCondition => {
  return PropertyTypeConditions[propertyType] ?? defaultPropertyTypeCondition;
};

export enum Project {
  LMAP = '14fc26a3-c09c-48df-a472-2047e569c08d',
  BB01 = 'e6e7f2bb-d30b-45a5-96e3-b57e6514896b',
}
