import { changePassword } from '@/services/auth.service';
import useAuthStore from '@/stores/auth.store';
import { useMutation } from '@tanstack/react-query';
import { App, Button, Form, Input, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useCallback } from 'react';

const PasswordForm = () => {
  const { setAccessToken } = useAuthStore(({ setAccessToken }) => ({
    setAccessToken,
  }));
  const [form] = useForm();
  const { message, modal } = App.useApp();

  const { mutateAsync: handleSubmit, isPending } = useMutation({
    mutationKey: ['change-password'],
    mutationFn: changePassword,
    onSuccess: () => {
      modal.success({
        title: 'លេខសម្ងាត់បានប្ដូរជោគជ័យ',
        content: 'លោកអ្នកត្រូវចូលប្រើប្រាស់ម្ដងទៀត',
        onOk: () => {
          form.resetFields();
          setAccessToken(null);
        },
        footer: (_, { OkBtn }) => (
          <>
            <OkBtn />
          </>
        ),
      });
    },
    onError: (error: any) => {
      message.error(
        error?.response?.data?.message || 'មិនអាចប្ដូរលេខកូដសម្ងាត់'
      );
    },
  });

  const confirmPwValidator = useCallback(
    ({ getFieldValue }: any) => ({
      validator(_: any, value: any) {
        if (!value || getFieldValue('new_password') === value) {
          return Promise.resolve();
        }
        return Promise.reject(new Error('លេខកូដសម្ងាត់ថ្មីទាំងពីរមិនដូចគ្នា'));
      },
    }),
    []
  );

  return (
    <>
      <Spin spinning={isPending} tip="កំពុងប្ដូរលេខកូដសម្ងាត់">
        <Form
          form={form}
          size="large"
          layout="vertical"
          onFinish={handleSubmit}
          autoComplete="off"
        >
          <Form.Item
            name="old_password"
            label="លេខកូដសម្ងាត់ចាស់"
            rules={[
              {
                required: true,
                message: 'សូមបញ្ជូលលេខកូដសម្ងាត់ចាស់',
              },
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="new_password"
            label="លេខសម្ងាត់ថ្មី"
            rules={[
              {
                required: true,
                message: 'សូមបញ្ជូលលេខកូដសម្ងាត់ថ្មី',
              },
            ]}
            hasFeedback
          >
            <Input.Password />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            label="បញ្ជាក់លេខកូដសម្ងាត់ថ្មី"
            dependencies={['new_password']}
            hasFeedback
            rules={[
              {
                required: true,
                message: 'សូមបញ្ជាក់លេខកូដសម្ងាត់ថ្មី',
              },
              confirmPwValidator,
            ]}
          >
            <Input.Password />
          </Form.Item>

          <Form.Item>
            <Button type="primary" block htmlType="submit" loading={isPending}>
              ប្ដូរលេខសម្ងាត់
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </>
  );
};

export default PasswordForm;
