import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '@/types/User.type';
import { immer } from 'zustand/middleware/immer';

export type AuthStoreType = {
  isAuthenticated: boolean;
  user: User | null;
  accessToken: string | null;
  setUser: (user: any) => void;
  setAccessToken: (accessToken: string | null) => void;
  setIsAuthenticated: (isAuthenticated: boolean) => void;
};

const useAuthStore = create<AuthStoreType>()(
  persist(
    immer((set) => ({
      isAuthenticated: false,
      user: null,
      accessToken: null,
      setUser: (user: any = {}) => {
        set({ user });
      },
      setAccessToken: (accessToken: string | null) => {
        set({ isAuthenticated: accessToken != null, accessToken });
      },
      setIsAuthenticated: (isAuthenticated) => {
        set({ isAuthenticated });
      },
    })),
    {
      name: 'authStore',
      partialize: ({ accessToken }) => ({ accessToken }),
    }
  )
);

export default useAuthStore;
