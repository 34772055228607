export enum Permission {
  SYSTEMATIC_REGISTRATION_LIST = 'SYSTEMATIC_REGISTRATION_LIST',
  SYSTEMATIC_REGISTRATION_CREATE = 'SYSTEMATIC_REGISTRATION_CREATE',
  SYSTEMATIC_REGISTRATION_UPDATE = 'SYSTEMATIC_REGISTRATION_UPDATE',

  READ_ONLY = 'READ_ONLY',
  DOCUMENT_CHECKLIST = 'DOCUMENT_CHECKLIST',
  PRINTING = 'PRINTING',

  SUB_SEQUENT_LIST = 'SUB_SEQUENT_LIST',
  SUB_SEQUENT_CREATE = 'SUB_SEQUENT_CREATE',
  SUB_SEQUENT_UPDATE = 'SUB_SEQUENT_UPDATE',

  ADDITIONAL_REGISTRATION_LIST = 'ADDITIONAL_REGISTRATION_LIST',
  ADDITIONAL_REGISTRATION_CREATE = 'ADDITIONAL_REGISTRATION_CREATE',
  ADDITIONAL_REGISTRATION_UPDATE = 'ADDITIONAL_REGISTRATION_UPDATE',

  MODIFY_AFTER_PD = 'MODIFY_AFTER_PD',
  SIGN_QR = 'SIGN_QR',

  SUPER_EDIT = 'SUPER_EDIT',
}
