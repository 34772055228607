import { ConfigProvider, App as AntdApp } from 'antd';
import locale from 'antd/locale/km_KH';
import { RouterProvider } from 'react-router-dom';
import { router } from './pages/routes';
import { StyleProvider } from '@ant-design/cssinjs';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
// import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AnimatedCompass from './components/AnimatedCompass';

export const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        theme={{
          token: { fontFamily: 'Kantumruy' },
          components: {
            Spin: {
              dotSize: 50,
            },
          },
        }}
        locale={locale}
        spin={{ indicator: <AnimatedCompass /> }}
      >
        <AntdApp>
          <StyleProvider hashPriority="high">
            <RouterProvider router={router} />
          </StyleProvider>
        </AntdApp>
      </ConfigProvider>
      {/* <ReactQueryDevtools initialIsOpen={false} /> */}
    </QueryClientProvider>
  );
};

export default App;
