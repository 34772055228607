import { useEffect } from 'react';

function useTitle(title: string) {
  // const titleRef = useRef(isBrowser ? document.title : '');
  useEffect(() => {
    document.title = title;

    return () => {
      document.title = 'Cadasteral Information System';
    };
  }, [title]);
}

export default useTitle;
