import {
  isLocalHost,
  isNextRelease,
  isProduction,
  isTraining,
  isTrainingDev,
} from '@/configs/environment';

// Telegram
export const TELEGRAM_BOT_TOKEN =
  '7611142087:AAH5WYD1cIDDlOhTksm1qErVsX4mwDjMd6g'; // @luma_cis_bot
export const NON_PRODUCTION_TELEGRAM_CHAT_ID = '-4518608204';
export const PRODUCTION_TELEGRAM_CHAT_ID = '-4589511716';
export const ERROR_LOGS_CHAT_ID = '-4529306442';

export const ENV_EMOJI = isTraining
  ? '🟡'
  : isTrainingDev
  ? '🟣'
  : isNextRelease
  ? '🔵'
  : isProduction
  ? '🟢'
  : '❓';

export const ENV_NAME = isTraining
  ? '🅣🅡🅐🅘🅝'
  : isTrainingDev
  ? '🅣🅡🅐🅘🅝 🅓🅔🅥'
  : isNextRelease
  ? '🅽🅴🆇🆃'
  : isLocalHost
  ? '🅛🅞🅒🅐🅛'
  : isProduction
  ? '🅟🅡🅞🅓🅤🅒🅣🅘🅞🅝'
  : 'Unknown';

// Telegram
const TELEGRAM_ENV_NAME_CHAT_ID_MAP = {
  '🅣🅡🅐🅘🅝': NON_PRODUCTION_TELEGRAM_CHAT_ID,
  '🅣🅡🅐🅘🅝 🅓🅔🅥': NON_PRODUCTION_TELEGRAM_CHAT_ID,
  '🅽🅴🆇🆃': NON_PRODUCTION_TELEGRAM_CHAT_ID,
  '🅛🅞🅒🅐🅛': NON_PRODUCTION_TELEGRAM_CHAT_ID,
  '🅟🅡🅞🅓🅤🅒🅣🅘🅞🅝': PRODUCTION_TELEGRAM_CHAT_ID,
  Unknown: NON_PRODUCTION_TELEGRAM_CHAT_ID,
};

export const TELEGRAM_CHAT_ID = TELEGRAM_ENV_NAME_CHAT_ID_MAP[ENV_NAME];
